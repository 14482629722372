import React, { useState } from "react";
import "./style.scss";
import downArrow from "../../Assets/Svg/downArrow.svg";

const PoolDetailsTab = ({ details, isOpen, onToggle }) => {
  return (
    <div className="poolDetailsTabWrapper">
      {!isOpen && (
        <div className="poolDetailsTab">
          <p>{details.name} (4)</p>
          <img src={downArrow} alt="downArrow" onClick={onToggle} />
        </div>
      )}

      {isOpen && (
        <div className="poolDetailsTabOpened">
          <div className="openTabHeader">
            <p>{details.name} (4)</p>
            <img
              src={downArrow}
              alt="downArrow"
              className="openedArrow"
              onClick={onToggle}
            />
          </div>

          {details.quantity && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Qty :</p>
              <p className="tabDetailsValue">{details.quantity}</p>
            </div>
          )}

          {details.size && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Size :</p>
              <p className="tabDetailsValue">{details.size}</p>
            </div>
          )}

          {details.brand && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Brand :</p>
              <p className="tabDetailsValue">{details.brand}</p>
            </div>
          )}

          {details.mfgDate && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Mfg Date :</p>
              <p className="tabDetailsValue">{details.mfgDate}</p>
            </div>
          )}

          {details.type && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Type :</p>
              <p className="tabDetailsValue">{details.type}</p>
            </div>
          )}

          {details.subType && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Sub-Type :</p>
              <p className="tabDetailsValue">{details.subType}</p>
            </div>
          )}

          {details.steps && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Steps :</p>
              <p className="tabDetailsValue">{details.steps}</p>
            </div>
          )}

          {details.Specifications && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Specifications :</p>
              <p className="tabDetailsValue">{details.Specifications}</p>
            </div>
          )}

{details.grade && (
            <div className="tabDetails">
              <p className="tabDetailsHeadings">Grade :</p>
              <p className="tabDetailsValue">{details.grade}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PoolDetailsTab;
