import React, { useState } from "react";
import "./style.scss";
import MerchantBottomNavBar from "../../Components/MerchantBottomNavBar/MerchantBottomNavBar";
import backArrow from "../../Assets/Svg/nativeBack.svg";
import search from "../../Assets/Svg/search.svg";
import mic from "../../Assets/Svg/mic.svg";
import micOff from "../../Assets/Svg/mic-off.svg";
import { useNavigate } from "react-router-dom";
import copyIcon from "../../Assets/Svg/copyImg.svg";
import PoolDetailsTab from "../../Components/PoolDetailsTab/PoolDetailsTab";
import Technicians from "../../Components/Technicians/Technicians";

const Assign = () => {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [openAssignPopUp, setOpenAssignPopUp] = useState(false);
  const [openTabs, setOpenTabs] = useState({});

  const poolDetails = [
    {
      id: 1,
      name: "Filtration",
      quantity: 1,
      size: "Small (400mm - 600mm)",
      brand: "Astral",
      mfgDate: "Less than a year",
    },
    {
      id: 2,
      name: "Filter Media",
      type: "Silica Sand",
      grade: "Nil",
    },
    {
      id: 3,
      name: "Ladder",
      type: "Club Ladder",
      subType: "Over Flow",
      steps: "5",
    },
    {
      id: 4,
      name: "LED Under Water",
      quantity: 8,
      Specifications: "14W/12V",
      brand: "Philips",
    },
  ];

  const technicians = [
    "Shunmuga",
    "Vignesh",
    "Jayesh",
    "Siddharth",
    "Saran",
    "Shunmuga",
    "Vignesh",
    "Jayesh",
    "Siddharth",
    "Saran",
  ];

  const toggleTab = (id) => {
    setOpenTabs((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <div className="assignWrapper">
      <div className="assign">
        <div className="assignHeader">
          <img
            src={backArrow}
            alt="nativeBack"
            className="assignBack"
            onClick={() => navigate(-1)}
          />

          <div className="searchBar">
            <div className="searchbarContainer">
              <input
                type="text"
                className="searchBarInput"
                placeholder="Search"
                // value={inputValue}
                // onChange={(e) => setInputValue(e.target.value)}
              />

              <img src={search} alt="assignIcon" className="assignIcon" />

              <img
                src={isListening ? micOff : mic}
                alt="micIcon"
                className="micIcon"
                // onClick={isListening ? stopListening : startListening}
              />
            </div>
          </div>
        </div>

        <div className="assignPoolDetails">
          <div className="assignPoolName">Above Ground Pool Maintenance</div>
          <div className="assignPoolAddress">
            4517 Washington Ave, Manchester, Ketunchy 389201
          </div>
        </div>

        <div className="assignDivider">
          <hr className="assignLine" />
          <p className="assignDividerText">Customer Details</p>
          <hr className="assignLine" />
        </div>

        <div className="cutomerDetailsWrapper">
          <div className="cutomerDetails">
            <div className="nameRow">
              <p className="assignRowHead">Name :</p>
              <p className="assignRowBody">Saran</p>
            </div>
          </div>
          <div className="cutomerDetails">
            <div className="nameRow">
              <p className="assignRowHead">Mobile Number :</p>
              <p className="assignRowBody assignNumber">
                +91 9482910292
                <img src={copyIcon} alt="copyIcon" />
              </p>
            </div>
          </div>
          <div className="cutomerDetails">
            <div className="nameRow">
              <p className="assignRowHead">Email ID :</p>
              <p className="assignRowBody">saran@email.com</p>
            </div>
          </div>
        </div>

        <div className="assignDivider">
          <hr className="assignLine" />
          <p className="assignDividerText">Pool Details</p>
          <hr className="assignLine" />
        </div>

        {poolDetails.map((item) => (
          <PoolDetailsTab
            key={item.id}
            details={item}
            isOpen={openTabs[item.id]}
            onToggle={() => toggleTab(item.id)}
          />
        ))}

        <div className="assignToBtn" onClick={() => setOpenAssignPopUp(true)}>
          <p>Assign to</p>
        </div>
        {!openAssignPopUp && (
          <div className="assignBottomNavBar">
            <MerchantBottomNavBar />
          </div>
        )}
      </div>
      {openAssignPopUp && (
        <div className="popUpContaine">
          <Technicians technicians={technicians} setOpenAssignPopUp={setOpenAssignPopUp}/>
        </div>
      )}
    </div>
  );
};

export default Assign;
