import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import poolImg from "../../Assets/Svg/PoolFullScreen.svg";
import backBtn from "../../Assets/Svg/nativeBack.svg";
import star from "../../Assets/Svg/Star.svg";
import dropDown from "../../Assets/Svg/chevrons-down.svg";
import PoolInfo from "../../Components/PoolInfo/PoolInfo";
import BottomNavbar from "../../Components/BottomNavBar/BottomNavbar";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomAlert from "../../Components/CustomAlert/CustomAlert";

const PoolDetails = () => {
  const [slotPopUpOpen, setSlotPopUpOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [formattedDate, setFormattedDate] = useState("");
  const [showMonthPopup, setShowMonthPopup] = useState(false);
  const [showYearPopup, setShowYearPopup] = useState(false);

  const navigate = useNavigate();
  const poolRef = useRef(null);

  const scrollToPoolInfo = () => {
    poolRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const months = [
    "January",
    "Feburary",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 9 }, (_, i) => currentYear + i);

  const monthsToDisplay = months.slice(new Date().getMonth());

  const handleMonthClick = () => {
    setShowMonthPopup(true);
  };

  const handleYearClick = () => {
    setShowYearPopup(true);
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setShowMonthPopup(false);
  };

  const handleYearChange = (year) => {
    setSelectedYear(year);
    setShowYearPopup(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.closest(".monthPopup") || e.target.closest(".yearPopup"))
      return;
    setShowMonthPopup(false);
    setShowYearPopup(false);
  };

  const getDaysArrayForCurrentMonth = (year, month) => {
    const totalDays = new Date(year, month + 1, 0).getDate();
    return Array.from({ length: totalDays }, (_, i) => i + 1);
  };

  const days = getDaysArrayForCurrentMonth(selectedYear, selectedMonth);

  const handleDateClick = (day) => {
    setSelectedDay(day);
    const dateString = `${day}/${selectedMonth + 1}/${selectedYear}`;
    setFormattedDate(dateString);
  };

  const [showAlert, setShowAlert] = useState(false);

  const handleNavigation = (targetPath) => {
    setShowAlert(true);
  };

  const handleYesClick = (targetPath) => {
    setShowAlert(false);
    navigate(targetPath, { state: { formattedDate } });
  };

  const handleNoClick = () => {
    setShowAlert(false);
  };

  const getRandomNumber = () => {
    return Math.floor(Math.random() * (80000 - 15000 + 1)) + 15000;
  };

  const randomNumber = getRandomNumber();

  return (
    <div className="poolDetailsBox">
      <div className="poolDetails">
        <img
          src={backBtn}
          alt="nativeBack"
          className="nativeBack"
          onClick={() => navigate(-1)}
        />
        <img src={poolImg} alt="poolImg" className="poolImg" />

        <div className="detailsHeader">
          <h2>Ground Pool</h2>
          <div className="star">
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
          </div>
        </div>

        <div className="aboutcontainer">
          <h4 className="aboutHeader">About</h4>
          <p className="aboutDetails">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>

        <div className="specifyBtn" onClick={scrollToPoolInfo}>
          <img src={dropDown} alt="arrowImg" className="arrowImg" />

          <Link to="/bookings">
            <p className="dropDownText">Specify Pool Details</p>
          </Link>

          <img src={dropDown} alt="arrowImg" className="arrowImg" />
        </div>

        {/* <div ref={poolRef}>
          <PoolInfo />
        </div> */}

        <p className="estimateText">Estimated AMC ₹{randomNumber}</p>

        <div className="bookSlotsBtn" onClick={() => setSlotPopUpOpen(true)}>
          <p>Book Slots</p>
        </div>
      </div>

      {slotPopUpOpen && (
        <div className="slotPopUpWrapper">
          <div className="slotPopUp">
            <div className="dragBar"></div>

            <h3>Book Your Slot</h3>

            <div className="dateWrapper">
              {/* <div className="weekContainer">
              {weeks.map((week) => (
                <div className="" key={week}>{week}</div>
              ))}
              </div> */}

              <div className="filters">
                <div className="monthSelect" onClick={handleMonthClick}>
                  <span>{months[selectedMonth]}</span>{" "}
                </div>

                <div className="yearSelect" onClick={handleYearClick}>
                  <span>{selectedYear}</span>
                </div>
              </div>

              {showMonthPopup && (
                <div className="monthContainer">
                  <div className="monthPopup">
                    {months.map((month, index) => (
                      <div
                        key={index}
                        className="monthOption"
                        onClick={() => handleMonthChange(index)}
                      >
                        {month}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {showYearPopup && (
                <div className="yearContainer">
                  <div className="yearPopup">
                    {years.map((year) => (
                      <div
                        key={year}
                        className="yearOption"
                        onClick={() => handleYearChange(year)}
                      >
                        {year}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className="dateComponent">
                {days.map((day) => (
                  <p
                    className={selectedDay === day ? "selectedDay" : "day"}
                    onClick={() => handleDateClick(day)}
                  >
                    {day}
                  </p>
                ))}
              </div>
            </div>

            <div
              className="confirmBtn"
              onClick={() => handleNavigation("/confirmation")}
            >
              <p>Confirm</p>
            </div>
          </div>
        </div>
      )}

      {showAlert && (
        <>
          <div className="backdrop" onClick={handleNoClick}></div>{" "}
          <div className="alertPopUp">
            <CustomAlert
              message="Do you like the booking? Fee of ₹500 will be charged during day of visit."
              onYes={() => handleYesClick("/confirmation")}
              onNo={handleNoClick}
            />
          </div>
        </>
      )}

      <div className="bottomNav">
        <BottomNavbar />
      </div>
    </div>
  );
};

export default PoolDetails;
