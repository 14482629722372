import React, { useEffect, useState } from "react";
import "./style.scss";
import snTab from "../../Assets/Svg/SNtab.svg";
import newTab from "../../Assets/Svg/Footer Tab.svg";
import inProcessTab from "../../Assets/Svg/inProcess.svg";
import completedTab from "../../Assets/Svg/completedTab.svg";
import settingsTab from "../../Assets/Svg/settingsTab.svg";
import inActiveSN from "../../Assets/Svg/inActive SNTab.svg";
import activeNew from "../../Assets/Svg/activeNew.svg";
import activeInProcess from "../../Assets/Svg/activeInProcess.svg";
import { useLocation, useNavigate } from "react-router-dom";

const MerchantBottomNavBar = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const [snTabActive, setSNTabActive] = useState({
    sntab: false,
    newTab: false,
    inProcessTab: false,
  });

  console.log(location.pathname);

  useEffect(() => {
    switch (location.pathname) {
      case "/DashBoard":
        setSNTabActive({
          snTab: true,
          newTab: false,
          inProcessTab: false,
        });
        break;
      case "/newBookings":
        setSNTabActive({
          snTab: false,
          newTab: true,
          inProcessTab: false,
        });
        break;
      case "/inProcess":
        setSNTabActive({
          snTab: false,
          newTab: false,
          inProcessTab: true,
        });
        break;
      case "/assign":
        setSNTabActive({
          snTab: false,
          newTab: false,
          inProcessTab: true,
        });
        break;
      default:
        setSNTabActive({
          snTab: false,
          newTab: false,
          inProcessTab: false,
        });
        break;
    }
  }, [location.pathname]);

  return (
    <div className="merchantBottomNavBar">
      <div className="navBarTabs">
        <img
          src={snTabActive.snTab ? snTab : inActiveSN}
          alt="snTab"
          onClick={() => navigate('/DashBoard')}
        />
        <img
          src={snTabActive.newTab ? activeNew : newTab}
          alt="newTab"
          onClick={() => navigate('/newBookings')}
        />
        <img
          src={
            snTabActive.inProcessTab
              ? activeInProcess
              : inProcessTab
          }
          alt="inProcessTab"
          onClick={() => navigate('/inProcess')}
        />
        <img src={completedTab} alt="completedTab" />
        {/* <img src={settingsTab} alt="settingsTab" /> */}
      </div>
    </div>
  );
};

export default MerchantBottomNavBar;
