import React, { useEffect } from "react";
import "./style.scss";
import back from "../../Assets/Svg/nativeBack.svg";
import confirm from "../../Assets/Svg/confirmationImg.svg";
import BottomNavbar from "../../Components/BottomNavBar/BottomNavbar";
import { useLocation, useNavigate } from "react-router-dom";

const Confirmation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { formattedDate } = location.state || {};

  return (
    <div className="confirmationWrapper">
      <div className="confirmation">
        <div className="confirmationBackBtn">
          <img
            src={back}
            alt="ConfirmationBackImage"
            onClick={() => navigate(-1)}
          />
        </div>

        <div className="confirmationContainer">
          <div className="confirmationHeader">
            <p>Thank you. Booked successfull !</p>
          </div>

          <div className="confirmationImg">
            <img src={confirm} alt="Confirm Image" />
          </div>

          <div className="expectedDate">
            <p>
              Our Expert will visit on reqested date!
            </p>
          </div>
        </div>
      </div>

      <div className="bottomNav">
        <BottomNavbar />
      </div>
    </div>
  );
};

export default Confirmation;
