import React, { useState } from "react";
import "./style.scss";

const Brand = ({ setFilterSelected, brands, selectedProductDetails, product }) => {
  const [selectedBrand, setSelectedBrand] = useState(0);

  return (
    <div className="brand">
      <div className="brandHeader">
        <p className="brandHeading">{product.name}</p>
        <p className="brandText">Select Brand</p>
      </div>

      <div className="brandFlexBox">
        {brands?.map((brand, index) => (
          <div
            className={selectedBrand === index ? "brandBox1" : "brandBox"}
            onClick={() => setSelectedBrand(index)}
          >
            <p>{brand}</p>
          </div>
        ))}
      </div>

      <div className="brandBtnContainer">
        <div className="brandbackAndNextBtn">
          <div
            className="brandbackBtn"
            onClick={() =>
              selectedProductDetails?.length > 1
                ? setFilterSelected(2)
                : setFilterSelected(1)
            }
          >
            Back
          </div>
          <div
            className="brandselectSizeNextBtn"
            onClick={() => setFilterSelected(4)}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brand;
