import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./style.scss";
import GoogleSignIn from "../../../Components/GoogleSignIn/GoogleSignIn";
import { Link, useNavigate } from "react-router-dom";
import show from "../../../Assets/Svg/eyeOpen.svg";
import hide from "../../../Assets/Svg/eyeClose.svg";

const CreateAccount = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm();

  const navigate = useNavigate()

  const onSubmit = (data) => {
    console.log("Form submitted:", data);
    navigate('/DashBoard')
    reset();
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className="createAccount">
      <h2>Create an account</h2>

      <form className="signInForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="sigInFormField">
          <label>
            <p className="signInlabelHeading">Name</p>
            <input
              type="text"
              {...register("name", { required: "Name is required" })}
            />
          </label>
          {errors.name && <p className="error">{errors.name.message}</p>}
        </div>

        <div className="sigInFormField">
          <label>
            <p className="signInlabelHeading">Email Address</p>
            <input
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Enter a valid email address",
                },
              })}
            />
          </label>
          {errors.email && <p className="error">{errors.email.message}</p>}
        </div>

        <div className="sigInFormField">
          <label>
            <p className="signInlabelHeading">Phone Number</p>
            <input
              type="text"
              {...register("phone", {
                required: "Phone Number is required",
                pattern: {
                  //   value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: "Enter a valid phone number",
                },
              })}
            />
          </label>
          {errors.phone && <p className="error">{errors.phone.message}</p>}
        </div>

        <div className="sigInFormField">
          <label>
            <p className="signInlabelHeading">Password</p>
            <div className="passwordContainer">
              <input
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: "Password is required",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                })}
              />
              <img
                src={showPassword ? show : hide}
                className="eyeIcon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
          </label>
          {errors.password && (
            <p className="error">{errors.password.message}</p>
          )}
        </div>

        <div className="sigInFormField">
          <label>
            <p className="signInlabelHeading">Confirm Password</p>
            <div className="confirmPasswordContainer">
              <input
                type={showConfirmPassword ? "text" : "password"}
                {...register("confirmPassword", {
                  required: "Confirm Password is required",
                  validate: (value) =>
                    value === watch("password") || "Passwords do not match",
                })}
              />
              <img
                src={showConfirmPassword ? show : hide}
                className="confirmEyeIcon"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </div>
          </label>
          {errors.confirmPassword && (
            <p className="error">{errors.confirmPassword.message}</p>
          )}
        </div>

        <div className="termAndConditionsContainer">
          <p className="termsAndConditions">
            By continuing, you agree to our <span>terms of service.</span>
          </p>
        </div>

        <div className="centerButtonWrapper">
          <button className="signInButton" type="submit">
            Sign In
          </button>
        </div>
      </form>

      <div class="separator-container">
        <div class="line"></div>
        <span class="separator-text">or</span>
        <div class="line"></div>
      </div>

      <GoogleSignIn />

      <p className="navigateToLogIn">
        Already have an account?
        <Link to="/">
          <span>Log in here</span>
        </Link>
      </p>
    </div>
  );
};

export default CreateAccount;
