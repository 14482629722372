import React, { useEffect, useState } from 'react'
import WelcomePage from './SNwelocme/WelcomePage'
import Getstarted from './GetStarted/Getstarted'

const DisplayScreen = () => {
    const [changeScreen, setChangeScreen] = useState(false)
    useEffect(() => {
        const timer = setTimeout(() => {
          setChangeScreen(true) 
        }, 3000);
    
        return () => clearTimeout(timer);
      }, [changeScreen]);
    

    return (
        <div className='displayScreen'>
            {changeScreen ? <Getstarted /> : <WelcomePage />}
        </div>
    )
    }

export default DisplayScreen
