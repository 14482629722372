import React from 'react'
import './style.scss'
import logo from  '../../../Assets/Svg/getStartedLogo.svg'
import { useNavigate } from 'react-router-dom'

const Getstarted = () => {
  const navigate = useNavigate()

  return (
    <div className='getStarted'>
      <div className="logoContainer">
        <img src={logo} alt="getStartedLogo" />
      </div>
      <div className="buttonContainer">
        <button onClick={() => navigate('/logIn')}>Get Started</button>
       {/* // <p>Already have an account <span onClick={() => navigate('/logIn')}>Log in</span></p> */}
      </div>
    </div>
  )
}

export default Getstarted
