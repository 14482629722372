import React, { useState } from "react";
import "./style.scss";
import { useForm } from "react-hook-form";
import GoogleSignIn from "../../../Components/GoogleSignIn/GoogleSignIn";
import { Link, useNavigate } from "react-router-dom";
import show from "../../../Assets/Svg/eyeOpen.svg";
import hide from "../../../Assets/Svg/eyeClose.svg";

const Login = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const navigate = useNavigate()

  const onSubmit = (data) => {
    console.log("Form submitted:", data);
    navigate('/home')
    reset();
  };

  const [password, setShowPassword] = useState("");

  return (
    <div className="lognIn">
      <div className="loginHeader">
        <h2>Login</h2>
        {/* <p className="headerWelcome">Welcome back to the app</p> */}
      </div>

      <form className="logInForm" onSubmit={handleSubmit(onSubmit)}>
        <div className="loginFieldContainer">
          <div className="logInFormField">
            <label>
              <p className="logInlabelHeading">Phone Number</p>
              <input
                type="text"
                maxLength={15}
                {...register("phone", {
                  required: "Phone Number is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Enter a valid phone number",
                  },
                })}
                onKeyDown={(e) => {
                  const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab"]; 
                  if (
                    !/^[0-9]$/.test(e.key) && 
                    !allowedKeys.includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </label>
            {/* <p className="OtpText">Get OTP</p> */}
            {errors.phone && <p className="error">{errors.phone.message}</p>}
          </div>
        </div>

        <div className="logInFormField">
          <label>
            <p className="logInlabelHeading">OTP</p>
            <div className="loginPasswordContainer">
                <input
                type="text"
                maxLength={10}
                {...register("password", {
                    required: "OTP is required",
                    // minLength: {
                    // value: 6,
                    // message: "Password must be at least 6 characters long",
                    // },
                })}
                />
            </div>
          </label>
          {errors.password && (
            <p className="error">{errors.password.message}</p>
          )}
        </div>

        <div className="logInFormCheckBox">
          <label>
            <input
              type="checkbox"
              className="keepLoggedIn"
              {...register("keepLoggedIn")}
            />
            <p>Keep me signed in</p>
          </label>
        </div>

        <div className="centerButtonWrapper">
          <button className="logInButton" type="submit">
            Login
          </button>
        </div>

        {/* <div class="separator-container">
          <div class="line"></div>
          <span class="separator-text">or sign in with</span>
          <div class="line"></div>
        </div>

        <GoogleSignIn />

        <Link to="/signIn">
          <p className="navigateToSignIn">Create an account</p>
        </Link> */}
      </form>
    </div>
  );
};

export default Login;
