import React, { useEffect } from 'react'
import './style.scss'
import logo from '../../../Assets/Svg/welcomeLogo.svg'

const WelcomePage = () => {
  return (
    <div className='welcomePage'>
        <img src={logo} alt='welcomeLogo'/>
    </div>
  )
}

export default WelcomePage
