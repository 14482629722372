import React from 'react';
import './style.scss';

const CustomAlert = ({ message, onYes, onNo }) => {
  return (
    <div className="customAlert">
      <div className="alertContent">
        <p>{message}</p>
        <div className="buttonWrapper">
          <button className="yesButton" onClick={onYes}>Yes</button>
          <button className="noButton" onClick={onNo}>No</button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
