import React, { useState } from 'react'
import './style.scss'

const Technicians = ({technicians, setOpenAssignPopUp}) => {

  const [selectedIndex, setSelectedIndex] = useState(null);

  return (
    <div className='technicians'>
        <div className="techniciansHeader">
        <p className="heading">Availabe Technicians</p>
      </div>

      <div className="techniciansFlexBox">
        {technicians?.map((technician, index) => (
          <div
          className={selectedIndex === index ? "techniciansBox1" : "techniciansBox"}
          onClick={() => setSelectedIndex(index)}
        >
          <p>{technician}</p>
        </div>
        ))}
      </div>

      <div className="btnContainer">
        <div className="backAndNextBtn">
          <div className="backBtn" onClick={() => setOpenAssignPopUp(false)}>
            Cancel
          </div>
          <div
            className="techniciansNextBtn"
            onClick={() => setOpenAssignPopUp(false)}
          >
            Assign
          </div>
        </div>
      </div>
    </div>
  )
}

export default Technicians
