import React, { useEffect, useState } from "react";
import "./style.scss";
import x from "../../Assets/Svg/xCloseBtn.svg";
import BottomNavbar from "../../Components/BottomNavBar/BottomNavbar";
import QuantitySpinner from "../../Components/QuantitySpinner/QuantitySpinner";
import SelectSizes from "../../Components/SelectSizes/SelectSizes";
import Brand from "../../Components/Brand/Brand";
import MfgDate from "../../Components/MfgDate/MfgDate";
import arrow from "../../Assets/Svg/dropDown arrow.svg";
import bin from "../../Assets/Svg/binAction.svg";
import pen from "../../Assets/Svg/penIcon.svg";
import { useNavigate } from "react-router-dom";

const Booking = () => {
  const [filterSelected, setFilterSelected] = useState(0);
  const [showTable, setShowTable] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [activeProductId, setActiveProductId] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProductDetails, setSelectedProductDetails] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState([]);

  const handleProductClick = (product) => {
    if (activeProductId !== product.id) {
      setFilterSelected(1);
      setActiveProductId(product.id);
      setSelectedProductDetails(product?.details);
      setSelectedBrand(product?.brand);
      setSelectedProduct(product);
    }
  };

  const products = [
    {
      id: 1,
      name: "Filtration",
      subTitle: "FRP Sand Filter",
      details: [
        "400 mm",
        "500 mm",
        "600 mm",
        "700 mm",
        "800 mm",
        "900 mm",
        "1000 mm",
        "1200 mm",
        "goes upto 2000 mm",
      ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 2,
      name: "Filter Media",
      // details: [
      //   "Silica Sand",
      //   "Quartz Sand",
      //   "Glass Media ( from grade 1 to 3 )",
      // ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 3,
      name: "Multiport Valve",
      // details: [
      //   '1.5 " MPV',
      //   '2" MPV',
      //   '1.5 " Automatic MPV',
      //   '2" Automatic MPV',
      // ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 4,
      name: "Battery Valve for commercial filters",
      // details: ["For filter from 1400 mm to 2000 mm filter"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 5,
      name: "Recirculation Pump",
      details: [
        "0.5 HP Pump with prefilter",
        "0.75 HP Pump with prefilter",
        "1.00 HP Pump with prefilter",
        "2.00 HP Pump with prefilter",
        "3.00 HP Pump with prefilter",
        "4.00 HP Pump with prefilter",
        "5.00 HP Pump with prefilter",
        "7.5 HP Pump with prefilter",
        "10.00 HP Pump with prefilter",
        "12.5 HP Pump with prefilter",
      ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 6,
      name: "Basin Equipments",
      // details: [
      //   "Floor Inlet",
      //   "Wall Inlet",
      //   "Vacuum Point",
      //   "Gutter Drain",
      //   "Overflow Gutter Grating",
      //   "Main Drain",
      //   "Circular Drain",
      //   "Square Drain",
      //   "SS Drain",
      // ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 7,
      name: "Ladder",
      subTitles: ["SS316 grade ladders", "SS304 grade ladders"],
      // details: {
      //   "SS316 grade ladders": {
      //     "Club Ladder": ["2 Step", "3 Step", "4 Step", "5 Step"],
      //     "Over Flow Ladder": ["2 Step", "3 Step", "4 Step", "5 Step"],
      //     "Standard Ladder": ["2 Step", "3 Step", "4 Step", "5 Step"],
      //   },
      //   "SS304 grade ladders": {
      //     "Club Ladder": ["2 Step", "3 Step", "4 Step", "5 Step"],
      //     "Over Flow Ladder": ["2 Step", "3 Step", "4 Step", "5 Step"],
      //     "Standard Ladder": ["2 Step", "3 Step", "4 Step", "5 Step"],
      //   },
      // },
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 8,
      name: "LED Underwater Lights",
      details: [
        "4 W / 12 V",
        "6 W / 12 V",
        "9 W / 12 V",
        "12 W / 12 V",
        "14 W / 12 V",
        "16 W / 12 V",
        "18 W / 12 V",
        "20 W / 12 V",
        "24 W / 12 V",
        "26 W / 12 V",
        "32 W / 12 V",
        "35 W / 12 V",
        "and above to be mentioned",
      ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 9,
      name: "Transformers / Drivers",
      details: ["100 Watts", "300 Watts"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 10,
      name: "Dosing System",
      // details: ["Manual Dosing pump", "Automatic Dosing Pump"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 11,
      name: "Chemical Tank with stirrer",
      details: ["100 Liter Tank", "200 Liter Tank", "500 Liter Tank"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 12,
      name: "UV System",
      // details: ["12 Watts to 120 Watts changing at each 10 watts"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 13,
      name: "Salt Chlorinator",
      details: [
        "12 Grams / hr",
        "24 Grams / hr",
        "32 Grams / hr",
        "40 Grams / hr",
      ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 14,
      name: "Ozonator",
      // details: ["Customer feed"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 15,
      name: "Neolysis",
      // details: ["Customer feed"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 16,
      name: "Other disinfection method",
      // details: ["Customer feed"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 17,
      name: "Cleaning Equipments",
      // details: [
      //   "Pool Brush",
      //   "Algae Brush",
      //   "Corner Brush",
      //   "Deep Net",
      //   "Flat Net",
      //   "Telescopic Handle - 9 mtr",
      //   "Telescopic Handle - 4 mtr",
      //   "Vacuum Hose Pipe - 12 mtr",
      //   "Vacuum Hose Pipe - 18 mtr",
      //   "Vacuum Hose Pipe - 24 mtr",
      //   "Vacuum Hose Pipe - 32 mtr",
      //   "Vacuum Head - Standard",
      //   'Vacuum Head - Aluminum - 18"',
      //   'Vacuum Head - Aluminum - 24"',
      // ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 18,
      name: "Water Test Kit",
      details: ["Test kit - Strips", "Test kit - Electronic"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 19,
      name: "Heat Pump",
      details: [
        "24 KW output",
        "32 KW output",
        "39 KW output",
        "45 KW output",
        "55 KW output",
        "82 KW output",
        "102 KW output",
        "120 KW output",
        "180 KW output",
        "250 KW output",
      ],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
    {
      id: 20,
      name: "Heater",
      details: ["12 KW", "18 KW", "24 KW", "32 KW"],
      brand: [
        "Astral",
        "Certikin",
        "Pentair",
        "Hornor Express",
        "Aqva Star",
        "Aqua",
        "Emaux",
        "Others",
      ],
    },
  ];

  const data = [
    { id: 1, qty: "01", Size: "S", brand: "Astral", Age: "<1yr" },
    { id: 2, qty: "01", Size: "L", brand: "Astral", Age: "2-3yrs" },
    { id: 3, qty: "01", Size: "M", brand: "Astral", Age: "1-2yrs" },
  ];

  const navigate = useNavigate();

  return (
    <div className="bookingsWrapper">
      <div className="bookingHeading">
        {filterSelected !== 0 && <div className=""></div>}
        <p className="bookingsHeader">Filtration & Circulation</p>
        {filterSelected !== 0 && (
          <img
            src={x}
            alt="closeBtn"
            className="bookingsCloseBtn"
            onClick={() => {
              setFilterSelected(0);
              setShowTable(false);
              setActiveProductId(null);
            }}
          />
        )}
      </div>

      <div className="bookingsContainer">
        <div className={filterSelected === 0 ? "bookings1" : "bookings"}>
          <div className="bookingBody">
            {products.map((product) => (
              <div className="bookingTableTabWrapper">
                <div className=""></div>
                <div
                  className={
                    showTable && activeProductId === product.id
                      ? "bookingTableTab"
                      : "bookingTab"
                  }
                  onClick={() => {
                    if (activeProductId !== product.id) {
                      setFilterSelected(1);
                      setActiveProductId(product.id);
                      handleProductClick(product);
                    }
                  }}
                  key={product.id}
                >
                  <p>
                    {!showTable && "+"} {product.name}
                  </p>
                  {showTable && (
                    <div
                      className={
                        openTable ? "dropDown-Arrow1" : "dropDown-Arrow"
                      }
                    >
                      <img
                        src={arrow}
                        alt="dropDown Arrow"
                        onClick={() => setOpenTable(!openTable)}
                      />
                    </div>
                  )}
                </div>

                {openTable && activeProductId === product.id && (
                  <div className="dropDownPopUp">
                    <table className="no-line-table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>QTY</th>
                          <th>Size</th>
                          <th>Brand</th>
                          <th>Age</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((row) => (
                          <tr key={row.id}>
                            <td>{row.id}</td>
                            <td>{row.qty}</td>
                            <td>{row.Size}</td>
                            <td>{row.brand}</td>
                            <td>{row.Age}</td>
                            <td>
                              <div className="actionIcons">
                                {/* <img src={bin} alt="binIcon" /> */}
                                <img src={pen} alt="editIcon" onClick={()=>setFilterSelected(1)}/>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            ))}
            {filterSelected === 0 && (
              <div className="bookingResetSaveBtn">
                <p className="resetBtn" onClick={() => setShowTable(false)}>
                  Reset
                </p>
                <p className="savebtn" onClick={() => navigate("/details")}>
                  Save
                </p>
              </div>
            )}
          </div>
        </div>

        {filterSelected === 0 && (
          <div className="bottomNav">
            <BottomNavbar />
          </div>
        )}

        <div
          className={
            filterSelected === 0
              ? "filterSelectionContainer1"
              : "filterSelectionContainer"
          }
        >
          {filterSelected === 1 && (
            <div className="spinnerWrapper">
              <QuantitySpinner
                product={selectedProduct}
                quantity={quantity}
                setQuantity={setQuantity}
              />
            </div>
          )}

          {filterSelected === 2 && (
            <div className="selectSizesWrapper">
              <SelectSizes
                product={selectedProduct}
                setFilterSelected={setFilterSelected}
                selectedProductDetails={selectedProductDetails}
              />
            </div>
          )}

          {filterSelected === 3 && (
            <div className="brandWrapper">
              <Brand
                product={selectedProduct}
                setFilterSelected={setFilterSelected}
                brands={selectedBrand}
                selectedProductDetails={selectedProductDetails}
              />
            </div>
          )}

          {filterSelected === 4 && (
            <div className="brandWrapper">
              <MfgDate
                product={selectedProduct}
                setFilterSelected={setFilterSelected}
                setShowTable={setShowTable}
              />
            </div>
          )}

          {filterSelected === 1 && (
            <div
              className="nextBtn"
              onClick={() =>
                selectedProductDetails?.length > 1
                  ? setFilterSelected(2)
                  : setFilterSelected(3)
              }
            >
              Next
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Booking;
