import React from "react";
import "./style.scss";
import poolImg from "../../Assets/Svg/bookedDetailsImg.svg";
import BottomNavbar from "../../Components/BottomNavBar/BottomNavbar";

const BookDetails = () => {
  return (
    <div className="bookingDetailsContainer">
      <div className="bookingDetails">
        <h1>Booking</h1>
        <div className="bookingTabContainer">
        <div className="bookedTab">
          <div className="wrapperComponent">
            <img src={poolImg} alt="poolImage" />

            <div className="bookedPoolInfo">
              <div className="bookedPoolInfo">Ground Pool</div>
              <div className="bookedPoolInfoDetails">
               In progress
              </div>
            </div>
          </div>

          <div className="bookedDate">
            <p>
              <span>12</span>
              <sup>th</sup> Nov
            </p>
          </div>
        </div>

        <div className="bookedTab">
          <div className="wrapperComponent">
            <img src={poolImg} alt="poolImage" />

            <div className="bookedPoolInfo">
              <div className="bookedPoolInfo">Ground Pool</div>
              <div className="bookedPoolInfoDetails">
              In progress
              </div>
            </div>
          </div>

          <div className="bookedDate">
            <p>
              <span>12</span>
              <sup>th</sup> Dec
            </p>
          </div>
        </div>
        </div>
      </div>

      
      
      <div className="bottomNav">
        <BottomNavbar />
      </div>
    </div>
  );
};

export default BookDetails;
