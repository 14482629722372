import React, { useState } from "react";
import "./style.scss";

const MfgDate = ({ setFilterSelected, setShowTable, product }) => {
  const [selectedBrand, setSelectedBrand] = useState(0);

  const mfgData = ["Less Than Year", "1-2 Years", "2-3 Years", "Above 4 Years"];

  return (
    <div className="mfg">
      <div className="mfgHeader">
        <p className="mfgHeading">{product.name}</p>
        <p className="mfgText">Select Manufacturing Date</p>
      </div>

      <div className="mfgFlexBox">
        {mfgData.map((mfg, index) => (
          <div
            className={selectedBrand === index ? "mfgBox1" : "mfgBox"}
            onClick={() => setSelectedBrand(index)}
          >
            <p>{mfg}</p>
          </div>
        ))}
      </div>

      <div className="mfgBtnContainer">
        <div className="mfgbackAndNextBtn">
          <div
            className="mfgbackBtn"
            onClick={() => {
              setFilterSelected(3);
            }}
          >
            Back
          </div>
          <div
            className="mfgselectSizeNextBtn"
            onClick={() => {
              setFilterSelected(0);
              setShowTable(true);
            }}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default MfgDate;
