import React, { useState } from "react";
import "./style.scss";
import MerchantBottomNavBar from "../../Components/MerchantBottomNavBar/MerchantBottomNavBar";
import backArrow from "../../Assets/Svg/nativeBack.svg";
import search from "../../Assets/Svg/search.svg";
import mic from "../../Assets/Svg/mic.svg";
import micOff from "../../Assets/Svg/mic-off.svg";
import { useNavigate } from "react-router-dom";

const NewBookings = () => {
  const [inputValue, setInputValue] = useState("");
  const [isListening, setIsListening] = useState(false);

  // let recognition;
  // if ("webkitSpeechRecognition" in window) {
  //   recognition = new window.webkitSpeechRecognition();
  //   recognition.continuous = false;
  //   recognition.interimResults = false;
  //   recognition.lang = "en-US";
  // }

  // const startListening = () => {
  //   if (!recognition) {
  //     alert("Speech recognition is not supported in your browser.");
  //     return;
  //   }
  //   setIsListening(true);
  //   recognition.start();

  //   recognition.onresult = (event) => {
  //     const transcript = event.results[0][0].transcript;
  //     setInputValue(transcript);
  //     setIsListening(false);
  //   };

  //   recognition.onerror = () => {
  //     setIsListening(false);
  //   };

  //   recognition.onend = () => {
  //     setIsListening(false);
  //   };
  // };

  // const stopListening = () => {
  //   if (recognition) {
  //     recognition.stop();
  //     setIsListening(false);
  //   }
  // };

  const navigate = useNavigate()

  return (
    <div className="newBookings">
      <div className="newBookingHeader">
        <img
          src={backArrow}
          alt="nativeBack"
          className="newBookingsBack"
          onClick={() => navigate(-1)}
        />

        <div className="searchBar">
          <div className="searchbarContainer">
            <input
              type="text"
              className="searchBarInput"
              placeholder="Search"
              // value={inputValue}
              // onChange={(e) => setInputValue(e.target.value)}
            />

            <img src={search} alt="homeSearchIcon" className="homeSearchIcon" />

            <img
              src={isListening ? micOff : mic}
              alt="micIcon"
              className="micIcon"
              // onClick={isListening ? stopListening : startListening}
            />
          </div>
        </div>
      </div>

      <div className="newBookingsBox">
        <div className="newBookingsContainer">
          <div className="newBookingsDetailsWrapper">
            <div className="newBookingsDetails">
              <p className="newBookingsPool">Above Ground Pool Maintenance</p>
              <p className="newBookingsAddress">
                4517 Washington Ave, Manchester, Ketunchy 389201
              </p>
            </div>
            <div className="newBookingsDate">
              <p className="date">
                12<sup className="powerOf"> th</sup>
              </p>
              <p className="month">DEC</p>
            </div>
          </div>
        </div>
        <div className="newBookingsBtnContainer">
          <div className="specificationBtn">View Pool Specification</div>
          <div className="assignBtn" onClick={()=>navigate('/inProcess')}>Assign</div>
        </div>
      </div>

      <div className="newBookingsBox">
        <div className="newBookingsContainer">
          <div className="newBookingsDetailsWrapper">
            <div className="newBookingsDetails">
              <p className="newBookingsPool">Above Ground Pool Maintenance</p>
              <p className="newBookingsAddress">
                4517 Washington Ave, Manchester, Ketunchy 389201
              </p>
            </div>
            <div className="newBookingsDate">
              <p className="date">
                12<sup className="powerOf"> th</sup>
              </p>
              <p className="month">DEC</p>
            </div>
          </div>
        </div>
        <div className="newBookingsBtnContainer">
          <div className="specificationBtn">View Pool Specification</div>
          <div className="assignBtn">Assign</div>
        </div>
      </div>

      <div className="newBookingsbottomNavbar">
        <MerchantBottomNavBar />
      </div>
    </div>
  );
};

export default NewBookings;
