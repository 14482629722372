import React, { useState } from "react";
import "./style.scss";
import MerchantBottomNavBar from "../../Components/MerchantBottomNavBar/MerchantBottomNavBar";
import backArrow from "../../Assets/Svg/nativeBack.svg";
import search from "../../Assets/Svg/search.svg";
import mic from "../../Assets/Svg/mic.svg";
import micOff from "../../Assets/Svg/mic-off.svg";
import { useNavigate } from "react-router-dom";

const InProcess = () => {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState("");
  const [isListening, setIsListening] = useState(false);

  return (
    <div className="inProcess">
      <div className="inProcessHeader">
        <img
          src={backArrow}
          alt="nativeBack"
          className="inProcessBack"
          onClick={() => navigate(-1)}
        />

        <div className="searchBar">
          <div className="searchbarContainer">
            <input
              type="text"
              className="searchBarInput"
              placeholder="Search"
              // value={inputValue}
              // onChange={(e) => setInputValue(e.target.value)}
            />

            <img src={search} alt="inProcessIcon" className="inProcessIcon" />

            <img
              src={isListening ? micOff : mic}
              alt="micIcon"
              className="micIcon"
              // onClick={isListening ? stopListening : startListening}
            />
          </div>
        </div>
      </div>

      <div className="inProcessContainer">
        <div className="inProcesPoolDetail">
            <div className="inProcessNameAddress">
                <p className="inProcessName">Above Ground Pool Maintenance asdsa</p>
                <p className="inProcessAddress">4517 Washington Ave, Manchester, Ketunchy 389201</p>
            </div>
            <div className="inProcessSchedule">
                <p className="inProcessDate">12<sup className="inProcessPower">th</sup></p>
                <p className="inProcessMonth">Dec</p>
            </div>
        </div>
        <div className="inProcessCompleteBtn" onClick={() => navigate('/assign')}>
            Complete
        </div>
      </div>

      <div className="inProcessContainer">
        <div className="inProcesPoolDetail">
            <div className="inProcessNameAddress">
                <p className="inProcessName">Above Ground Pool Maintenance asdsa</p>
                <p className="inProcessAddress">4517 Washington Ave, Manchester, Ketunchy 389201</p>
            </div>
            <div className="inProcessSchedule">
                <p className="inProcessDate">12<sup className="inProcessPower">th</sup></p>
                <p className="inProcessMonth">Dec</p>
            </div>
        </div>
        <div className="inProcessCompleteBtn">
            Complete
        </div>
      </div>
      

      <div className="inProcessBottomNavBar">
        <MerchantBottomNavBar />
      </div>
    </div>
  );
};

export default InProcess;
