import React, { useEffect, useState } from "react";
import "./style.scss";
import snTab from "../../Assets/Svg/SNtab.svg";
import bookingsTab from "../../Assets/Svg/bookingsTab.svg";
import chat from "../../Assets/Svg/chatTab.svg";
import rewardTab from "../../Assets/Svg/rewardsTab.svg";
import settingsTab from "../../Assets/Svg/settingsTab.svg";
import { useLocation, useNavigate } from "react-router-dom";
import inActiveSN from "../../Assets/Svg/inActive SNTab.svg";
import activeBookings from "../../Assets/Svg/ActiveBookingsTab.svg";
import CustomAlert from "../CustomAlert/CustomAlert";

const BottomNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [snTabActive, setSNTabActive] = useState(false);
  const [bookingsTabActive, setBookingsTabActive] = useState(false);
  const [targetPath, setTargetPath] = useState("");

  useEffect(() => {
    if (location.pathname === "/bookingDetails") {
      setBookingsTabActive(true);
    } else {
      setSNTabActive(true);
    }
  }, [location]);

  // const handleNavigation = (targetPath) => {
  //   if (location.pathname === "/bookings") {
  //     const userConfirmed = window.confirm(
  //       "All the unsaved data will be lost. Are you sure?"
  //     );
  //     if (userConfirmed) {
  //       navigate(targetPath);
  //     }
  //   } else {
  //     navigate(targetPath);
  //   }
  // };

  const [showAlert, setShowAlert] = useState(false);

  const handleNavigation = (path) => {
    if (location.pathname === "/bookings") {
      setTargetPath(path);
      setShowAlert(true);
    } else {
      navigate(path);
    }
  };

  const handleYesClick = () => {
    setShowAlert(false);
    navigate(targetPath);
  };

  const handleNoClick = () => {
    setShowAlert(false);
  };

  return (
    <div className="bottomNavBar">
      <div className="leftNavTabs">
        <img
          src={snTabActive ? snTab : inActiveSN}
          alt="snTab"
          className="sideTab"
          onClick={() => handleNavigation("/home")}
        />
        {/* <img src={bookingsTab} alt="snTab" className="sideTab" /> */}
      </div>

      {/* <div className="chatContainer">
        <img src={chat} alt="snTab" className="snChatTab" />
      </div> */}

      {showAlert && (
        <>
          <div className="backdrop" onClick={handleNoClick}></div>{" "}
          <div className="alertPopUp">
            <CustomAlert
              message="All the unsaved data will be lost. Are you sure?"
              onYes={handleYesClick}
              onNo={handleNoClick}
            />
          </div>
        </>
      )}

      <div className="rightNavTabs">
        <img
          src={bookingsTabActive ? activeBookings : bookingsTab}
          alt="snTab"
          className="sideTab"
          onClick={() => handleNavigation("/bookingDetails")}
        />
        {/* <img src={settingsTab} alt="snTab" className="sideTab" /> */}
      </div>
    </div>
  );
};

export default BottomNavbar;
