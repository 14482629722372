import React from "react";
import "./style.scss";
import MerchantBottomNavBar from "../../Components/MerchantBottomNavBar/MerchantBottomNavBar";
import heading from "../../Assets/Svg/dashboardHeading.svg";
import { useNavigate } from "react-router-dom";

const MerchantDashBoard = () => {
  const navigate = useNavigate();

  return (
    <div className="merchantDashBoard">
      <div className="header">
        <img src={heading} alt="dashBoardHeading" />
        <p>14/12/2024</p>
      </div>

      <div className="bookingsTrackerTab">
        <div className="newBookingsTab" onClick={() => navigate("/newBookings")}>
          <p>New Bookings</p>
          <h1>03</h1>
        </div>
        <div className="activeServices">
          <p>Active Services</p>
          <h1>06</h1>
        </div>
        <div className="completedServices">
          <p>Completed Services</p>
          <h1>25</h1>
        </div>
      </div>

      <div className="DashBoardbottomNavbar">
        <MerchantBottomNavBar />
      </div>
    </div>
  );
};

export default MerchantDashBoard;
