import React, { useState } from "react";
import "./style.scss";

const SelectSizes = ({ setFilterSelected, selectedProductDetails, product }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="selectSizes">
      <div className="selectSizesHeader">
        <p className="heading">{product.name}</p>
        <p className="selectSizesText">Select Sizes</p>
      </div>

      <div className="sizeFlexBox">
        {selectedProductDetails?.map((detail, index) => (
          <div
          className={selectedIndex === index ? "sizeBox1" : "sizeBox"}
          onClick={() => setSelectedIndex(index)}
        >
          <p>{detail}</p>
        </div>
        ))}
      </div>

      <div className="btnContainer">
        <div className="backAndNextBtn">
          <div className="backBtn" onClick={() => setFilterSelected(1)}>
            Back
          </div>
          <div
            className="selectSizeNextBtn"
            onClick={() => setFilterSelected(3)}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectSizes;
