import React from 'react'
import './style.scss'
import icon from '../../Assets/Svg/googleIcon.svg'

const GoogleSignIn = () => {
  return (
    <div className='googleSignIn'>
        <div className="googleSignInContainer">
            <img src={icon} alt="googlIcon" />
            <p>Continue with Google</p>
        </div>
    </div>
  )
}

export default GoogleSignIn
