import { ToastContainer } from 'react-toastify';
import Routers from './Routers';

function App() {
  return (
    <div className="App">
      <ToastContainer autoClose={3000} />
     <Routers />
    </div>
  );
}

export default App;
