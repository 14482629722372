import React, { useState } from "react";
import locationIcon from "../../Assets/Svg/location.svg";
import downArrow from "../../Assets/Svg/downArrow.svg";
import mic from "../../Assets/Svg/mic.svg";
import micOff from "../../Assets/Svg/mic-off.svg";
import dummyImg from "../../Assets/Svg/videoImg.svg";
import poolImg from "../../Assets/Svg/GroundPoolImg.svg";
import heart from "../../Assets/Svg/Favorite.svg";
import "./style.scss";
import BottomNavbar from "../../Components/BottomNavBar/BottomNavbar";
import star from "../../Assets/Svg/Star.svg";
import search from "../../Assets/Svg/search.svg";
import { Link } from "react-router-dom";
import aboveGroundPool from '../../Assets/Svg/AboveGroundPoolImg.svg'
import indoorPool from '../../Assets/Svg/indoorPoolImg.svg'
import infinityPool from '../../Assets/Svg/infinityPoolImg.svg'


const HomeScreen = () => {
  const [inputValue, setInputValue] = useState("");
  const [isListening, setIsListening] = useState(false);

  let recognition;
  if ("webkitSpeechRecognition" in window) {
    recognition = new window.webkitSpeechRecognition();
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = "en-US";
  }

  const startListening = () => {
    if (!recognition) {
      alert("Speech recognition is not supported in your browser.");
      return;
    }
    setIsListening(true);
    recognition.start();

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setInputValue(transcript);
      setIsListening(false);
    };

    recognition.onerror = () => {
      setIsListening(false);
    };

    recognition.onend = () => {
      setIsListening(false);
    };
  };

  const stopListening = () => {
    if (recognition) {
      recognition.stop();
      setIsListening(false);
    }
  };

  return (
    <div className="homeScreenContainer">
      <div className="homeScreen">
        {/* <div className="locationContainer">
          <img src={locationIcon} alt="locationIcon" className="locationIcon" />
          <div className="locationInfo">
            <div className="locationHeader">
              <p className="locationInfoHeading">11C</p>
              <img src={downArrow} alt="downArrow" className="downArrow" />
            </div>
            <p className="locationDetails">abcde, Tamilnadu, India</p>
          </div>
        </div>

        <div className="searchBar">
          <div className="searchbarContainer">
            <input
              type="text"
              className="searchBarInput"
              placeholder="Search"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />

            <img src={search} alt="homeSearchIcon" className="homeSearchIcon" />

            <img
              src={isListening ? micOff : mic}
              alt="micIcon"
              className="micIcon"
              onClick={isListening ? stopListening : startListening}
            />
          </div>
        </div> */}

        {/* <div className="videoContainer">
          <img src={dummyImg} alt="video" />
        </div> */}

        <p className="poolDetails">Pick Your Pool Type, We'll Make it shine!</p>

        <div className="poolDetailsContainer">
          <Link to="/details">
            <div className="poolBoxContainer">
              <div
                className="poolBox"
                style={{
                  backgroundImage: `url(${poolImg})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "200px",
                  width: "100%",
                }}
              >
                {/* <img src={poolImg} alt="poolImg" className="poolDetailsImage" /> */}
                {/* <div className="favouriteContainer">
                  <img src={heart} alt="favourite" />
                </div> */}
                <div className="typesContainer">
                  <p className="poolTypes">Ground Pool Maintenance</p>
                  <div className="star">
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/details">
            <div className="poolBoxContainer">
              <div
                className="poolBox"
                style={{
                  backgroundImage: `url(${aboveGroundPool})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "200px",
                  width: "100%",
                }}
              >
                {/* <img src={poolImg} alt="poolImg" className="poolDetailsImage" /> */}
                {/* <div className="favouriteContainer">
                  <img src={heart} alt="favourite" />
                </div> */}
                <div className="typesContainer">
                  <p className="poolTypes">Above Ground Pool Maintenance</p>
                  <div className="star">
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/details">
            <div className="poolBoxContainer">
              <div
                className="poolBox"
                style={{
                  backgroundImage: `url(${infinityPool})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "200px",
                  width: "100%",
                }}
              >
                {/* <img src={poolImg} alt="poolImg" className="poolDetailsImage" /> */}
                {/* <div className="favouriteContainer">
                  <img src={heart} alt="favourite" />
                </div> */}
                <div className="typesContainer">
                  <p className="poolTypes">Infinity Pool Maintenance</p>
                  <div className="star">
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                  </div>
                </div>
              </div>
            </div>
          </Link>

          <Link to="/details">
            <div className="poolBoxContainer">
              <div
                className="poolBox"
                style={{
                  backgroundImage: `url(${indoorPool})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "200px",
                  width: "100%",
                }}
              >
                {/* <img src={poolImg} alt="poolImg" className="poolDetailsImage" /> */}
                {/* <div className="favouriteContainer">
                  <img src={heart} alt="favourite" />
                </div> */}
                <div className="typesContainer">
                  <p className="poolTypes">Indoor Pool Maintenance</p>
                  <div className="star">
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                    <img src={star} alt="star" />
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <div className="bottomNav">
        <BottomNavbar />
      </div>
    </div>
  );
};

export default HomeScreen;
