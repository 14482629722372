import React from 'react'
import './style.scss'

const QuantitySpinner = ({quantity, setQuantity, product}) => {
  return (
    <div className='quantitySpinner'>
        <div className="spinnerheader">
            <p>{product.name}</p>
            <p className='quantityText'>Select Quantity</p>
        </div>

        <div className="spinner">
            <div className="minusBtn" onClick={()=>{
              quantity > 1 && setQuantity(quantity - 1)
            }}>-</div>
            <div className="spinnerValue">{String(quantity).padStart(2,0)}</div>
            <div className="plusBtn" onClick={()=>setQuantity(quantity + 1)}>+</div>
        </div>
    </div>
  )
}

export default QuantitySpinner
