import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import DisplayScreen from './Pages/WelcomePage/DisplayScreen'
import CreateAccount from './Pages/Auth/CreateAccount/CreateAccount'
import Login from './Pages/Auth/Login/Login'
import HomeScreen from './Pages/HomeScreen/HomeScreen'
import PoolDetails from './Pages/PoolDetails/PoolDetails'
import Booking from './Pages/Booking/Booking'
import BookDetails from './Pages/BookingDetails/BookDetails'
import Confirmation from './Pages/ConfirmationScreen/Confirmation'
import MerchantDashBoard from './Pages/MerchantDashBoard/MerchantDashBoard'
import NewBookings from './Pages/NewBookings/NewBookings'
import InProcess from './Pages/InProcess/InProcess'
import Assign from './Pages/Assign/Assign'

const Routers = () => {
  return (
    <div className='routers'>
      <BrowserRouter>
        <Routes>
            <Route path='/' element={<DisplayScreen />} />
            <Route path='/signIn' element={<CreateAccount />} />
            <Route path='/logIn' element={<Login />} />
            <Route path='/home' element={<HomeScreen />} />
            <Route path='/details' element={<PoolDetails />} />
            <Route path='/bookings' element={<Booking />} />
            <Route path='/confirmation' element={<Confirmation />} />
            <Route path='/bookingDetails' element={<BookDetails />} />
            <Route path='/DashBoard' element={<MerchantDashBoard />}/>
            <Route path='/newBookings' element={<NewBookings />} />
            <Route path='/inProcess' element={<InProcess />} />
            <Route path='/assign' element={<Assign />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default Routers
